import { gql, useQuery } from '@apollo/client'
import { PageRendererProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import { map } from 'ramda'
import React from 'react'
import styled from 'styled-components'

import bannerImage from '../../assets/static/banner/banner1.jpg'
import PageBanner from '../../components/Banner/PageBanner'
import BreadcrumbBar from '../../components/BreadcrumbBar'
import { NewDoctorTeam } from '../../components/Cards'
import { GridItem, GridLayout } from '../../components/Grid'
import Layout, { ThemeProvider } from '../../components/Layout'
import PageContent from '../../components/PageContent'
import Seo from '../../components/Seo'
import { ContentTitle } from '../../components/Titles/ContentTitle'
import { space } from '../../constants/length'
import { DoctorListQuery, DoctorListQueryVariables } from '../../types/types'

export const doctorListQuery = gql`
  query DoctorList($query: DoctorsQuery, $page: Int, $limit: Int, $sort: String) {
    doctorsList(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        created
        location
        title
        name
        elite
        image
        strength
        experience
        commend
        order
      }
      page
      limit
      total
    }
  }
`

const BreadcrumbBarWrapper = styled.div`
  margin: ${space.l}px 0;
`

const StyledContentTitle = styled(ContentTitle)`
  margin-bottom: ${space.l}px;
`

const StyledPageContent = styled(PageContent)`
  margin-bottom: 84px;
`

const ContentWrapper = styled.div`
  padding: 0 ${space.s}px;
`

const DoctorTeamWrapper = styled.div`
  margin-bottom: ${space.l}px;
`

const Team = (props: PageRendererProps) => {
  const { formatMessage } = useIntl()
  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'breadcrumb.team' }),
      path: '/team'
    }
  ]
  const { data } = useQuery<DoctorListQuery, DoctorListQueryVariables>(doctorListQuery, {
    variables: {
      limit: 20,
      sort: 'order',
      query: {
        elite: true
      }
    }
  })

  const pageData = data?.doctorsList?.docs ?? []

  return (
    <Layout {...props}>
      <Seo
        title='日不落牙醫集團｜專業醫師團隊：台北、新竹、台中、台南'
        metaDescription='我們是牙齒矯正、陶瓷貼片的美學專家，擁有台大、北醫多位矯正專科醫師，提供隱形牙套、戴蒙矯正、陶瓷貼片、人工植牙等牙科治療，遍布台北、新竹、台中、台南，提供給您最專業舒適的醫療環境。'
      />
      <PageBanner imageUrl={bannerImage} title={formatMessage({ id: 'banner.team' })} subtitle='Team' />
      <StyledPageContent>
        <ThemeProvider>
          <GridLayout templateColumns={['100%', '100%', '1fr 8fr 1fr']}>
            <GridItem column={['auto', 'auto', '2 / 3']}>
              <ContentWrapper>
                <BreadcrumbBarWrapper>
                  <BreadcrumbBar routes={routes} />
                </BreadcrumbBarWrapper>
                <StyledContentTitle>{formatMessage({ id: 'pageTitle.team' })}</StyledContentTitle>
                <GridLayout templateColumns={['100%', '1fr 1fr', '1fr 1fr']} columnGap={['unset', '80px', '160px']} rowGap={['24px', '80px']}>
                  {map(
                    ({ id, name, title, location, image, strength, experience }) => (
                      <DoctorTeamWrapper key={id}>
                        <NewDoctorTeam name={name} title={title} location={location} doctorImageUrl={image} strength={strength} experience={experience} />
                      </DoctorTeamWrapper>
                    ),
                    pageData
                  )}
                </GridLayout>
              </ContentWrapper>
            </GridItem>
          </GridLayout>
        </ThemeProvider>
      </StyledPageContent>
    </Layout>
  )
}

export default Team
